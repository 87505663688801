import { atom } from 'recoil';
import { ClimberProfile } from '../../types';

const signedInState = atom({
  key: 'signedInState',
  default: false,
});

const uid = atom({
  key: 'uid',
  default: '',
});

const currentUser = atom({
  key: 'currentUser',
  default: {} as ClimberProfile,
});

const friendList = atom({
  key: 'friendList',
  default: [],
});

const excursionLists = atom({
  key: 'excursionList',
  default: [],
});

const friendRequest = atom({
  key: 'friendRequest',
  default: [],
});

const myLocation = atom({
  key: 'myLocation',
  default: {},
});
const expoPushToken = atom({
  key: 'expoPushToken',
  default: '',
});

const climbTypes = atom({
  key: 'climbTypes',
  default: [],
});

const equipment = atom({
  key: 'equipment',
  default: [],
});

const newMsg = atom({
  key: 'newMsg',
  default: false
});

const msgCount = atom({
  key: 'msgCount',
  default: {}
});

const expNotifcation = atom({
  key: 'expNotifcation',
  default: false
});

const reqNotification = atom({
  key: 'reqNotification',
  default: []
});

const isProfileComplete = atom({
  key: 'isProfileCOmplete',
  default: true
});

export {
  currentUser,
  excursionLists,
  expoPushToken,
  friendList,
  friendRequest,
  myLocation,
  signedInState,
  uid,
  climbTypes,
  equipment,
  newMsg,
  msgCount,
  expNotifcation,
  reqNotification,
  isProfileComplete
};
