import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Page from '../components/Page';
import { useGetAllUsersAdmin, useSearchUsers } from '../hooks/useAllUsers';
import Strings from '../constants/Strings';
import { ClimberProfile } from '../../types';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { Searchbar, IconButton, ActivityIndicator } from 'react-native-paper';
import FriendList from '../components/FriendList';
import { FirebaseAuth, Firestore } from '../constants/Firebase';
import { Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import LoadingModal from '../components/LoadingModal';

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    marginTop: theme.spacing(2),
    borderRadius: 15,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  nameContainer: { display: 'flex', justifyContent: 'flex-start' },
  button: {
    width:100,
    borderRadius: 8,
  },
  viewButtonLabel: { 
    textTransform: "none",
    fontSize:10,
  },
}));
export default function AdminPage() {
  const ref = Firestore.collection('Users');
  const classes = useStyles();
  const { userLoading, userList } = useGetAllUsersAdmin('joined');
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const {loading,results: searchResults,error,errorMessage,SearchUsers,SearchFilter,} = useSearchUsers();
  const [visible,setVisible] = useState(false);

  const blockUser = async (uid: string) => {
    await fetch(Strings.BlockUsers, {
      method: 'POST',
      body: JSON.stringify({
        uid: uid,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      ref.doc(uid).update({ disabled: true });
    });
  };

  const deleteUser = async (uid: string) => {
    setVisible(true);
    await fetch(Strings.DeleteUsers, {
      method: 'POST',
      body: JSON.stringify({
        uid: uid,
        me: FirebaseAuth.currentUser?.uid ?? '',
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      ref.doc(uid).update({ disabled: true });
      setVisible(false);
    })
    .catch(()=>{
      setVisible(false);
    });
  }

  const RenderPage = () => {
    return (
      <>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
            <TableCell>Joined</TableCell>
              <TableCell>ID</TableCell>
              <TableCell align='center'>Name</TableCell>
              <TableCell align='right'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userLoading ? (
              <TableRow>
                <TableCell align='center' colSpan={3}>
                  <CircularProgress color='primary' />
                </TableCell>
              </TableRow>
            ) : (
              userList.map((user: ClimberProfile) => (
                <TableRow key={user.uid}>
                  <TableCell>{user.joined ? moment(user.joined).tz(moment.tz.guess()).format('D MMMM, YYYY hh:mm A z') : ''}</TableCell>
                  <TableCell>{user.uid}</TableCell>
                  <TableCell align='center'>
                    <div className={classes.nameContainer}>
                      <Avatar
                        style={{ marginRight: 20, borderRadius: 10, }}
                        variant={'square'}
                        alt={user.firstName}
                        src={user.image}
                      />
                      {' ' + user.firstName + ' ' + user.lastName}
                    </div>
                  </TableCell>
                  <TableCell align='right' style={{flexDirection:'row'}} >
                    {
                      //@ts-ignore
                    user.status!=='admin'&&<><Button
                      disabled={user.disabled}
                      className={classes.button}
                      classes={{ label: classes.viewButtonLabel }}
                      variant='contained'
                      color='primary'
                      size={'small'}
                      onClick={() => {
                        blockUser(user.uid);
                      }}
                    >
                      <Typography variant={'subtitle2'} > Block User </Typography>
                    </Button>

                    <Button
                      className={classes.button}
                      classes={{ label: classes.viewButtonLabel }}
                      variant='contained'
                      color='secondary'
                      size={'small'}
                      onClick={() => {
                        const res = confirm('Are you sure you want to delete this user?');
                        if(res) {
                          deleteUser(user.uid);
                        }
                        else {

                        }
                      }}
                    >
                      <Typography variant={'subtitle2'} > Delete User </Typography>
                    </Button></>}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </>
    );
  };

  const RenderSearchResults = () => {
    //console.log(searchResults);
    return (
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align='right'>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userLoading ? (
            <TableRow>
              <TableCell align='center' colSpan={3}>
                <CircularProgress color='primary' />
              </TableCell>
            </TableRow>
          ) : (
            searchResults.map((user: ClimberProfile) => (
              <TableRow key={user.uid}>
                <TableCell>{user.uid}</TableCell>
                <TableCell>
                  <div className={classes.nameContainer}>
                    <Avatar
                      style={{ marginRight: 20 }}
                      variant={'square'}
                      alt={user.firstName}
                      src={user.image}
                    />
                    {' ' + user.firstName + ' ' + user.lastName}
                  </div>
                </TableCell>
                <TableCell align='right'>
                  <Button
                    disabled={user.disabled}
                    variant='contained'
                    color='primary'
                    size={'small'}
                    onClick={() => {
                      blockUser(user.uid);
                    }}
                  >
                    Block User
                  </Button>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      // <FriendList
      //   callBack={() => {
      //     setShow(false);
      //   }}
      //   height={'100%'}
      //   loading={false}
      //   friendList={searchResults}
      //   type={'friends'}
      // />
    );
  };
  return (
    <Page title='Dashboard' layout='dashboard'>
      <>
        {show && (
          <IconButton
            style={{ marginLeft: -15 }}
            icon='close'
            // color={theme.colors.primary}
            size={20}
            onPress={() => {
              setShow(false);
              setSearch('');
            }}
          />
        )}
      </>

      <Searchbar
        style={{ width: '100%', height: 50 }}
        placeholder='Search Users'
        value={search}
        // iconColor={theme.colors.primary}
        onChangeText={(text) => {
          if (!show) setShow(true);
          setSearch(text);
          SearchUsers(search);
        }}
        onIconPress={() => {
          setShow(true);
        }}
        onFocus={() => {
          setShow(true);
        }}
        autoFocus={show}
        //onBlur={()=>{ if(!search) setShow(false); }}
        onSubmitEditing={() => {
          SearchUsers(search);
        }}
      />
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={'Current Users ('+(show? searchResults.length : userList.length)+')'}
          titleTypographyProps={{variant:'body1'}}
        />
        <CardContent className={classes.paper}>
        {show ? (
          loading ? (
            <>
              <ActivityIndicator
                animating={true}
                style={{ height: '90%', marginRight: 15 }}
              />
            </>
          ) : (
            <RenderSearchResults />
          )
        ) : (
          <RenderPage />
        )}
        </CardContent>
      </Card>
      <LoadingModal visible={visible} />
    </Page>
  );
}

// const styles = StyleSheet.create({
// footer-copyright:{flex:none,
//   width:100%,
//   display:inline-flex,
//   justify-content:center}
// })
