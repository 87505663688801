export default {
  //Api
  BlockUsers:
    'https://us-central1-climbunity-df5fe.cloudfunctions.net/blockUser',
  DeleteUsers:
    'https://us-central1-climbunity-df5fe.cloudfunctions.net/deleteUser',
  HelloWorld:
    'https://us-central1-climbunity-df5fe.cloudfunctions.net/helloWorld',

  //Texts
  WelcomeMsg: 'Hello ',
  FullAlert: ' is unfortunately full. For more details Contact ',
  InvLater:
    'Expedition Created. Feel free to invite more friends to your expedition later.',
  ToEmail: 'anderson@cenedex.com',
  IncompleteProfileAction:
    'Your profile is incomplete.\nPlease fill out your profile to continue this action.', //when user tries action but profile incomplete
  IncompleteProfileMsg: 'Your profile is incomplete. Please fill it out.',
  ShareMsg1: 'Sharing ',
  ShareMsg2: ' from climbUnity.',
  ShareExcursionUrl: 'https://climbunity.com/excursiondetails/?path=',
  UnableToShare: 'Unable to share expedition at this tine.',
  ExpeditionNotFound: "I'm sorry, this expedition has been deleted.",
  //Images
  AuthLogo: require('../assets/Logo-with-text_white-outline2.png'),
  Logo: require('../assets/logo.png'),
  PlaceHolderImage: require('../assets/placeHolder.png'),
  PlaceHolderImageDisabled: require('../assets/placeHolderDisabled.png'),
  PersonMarker: require('../assets/personIcon.png'),
  PersonPlaceHolder: require('../assets/personPlaceHolder.png'),
  MapIcon: require('../assets/MapIcon.png'),
  //Urls
  Appstore: 'https://apps.apple.com/us/app/climbunity/id1573491875',
  Playstore:
    'https://play.google.com/store/apps/details?id=com.climbunity.android',
  //Options
  Climbtypes: [
    {
      label: 'Biking',
      value: 'Biking',
      equipment: [
        'Bike',
        'Gloves',
        'Helmet',
        'Hydration',
        'Multi Tool',
        'Pump',
      ],
    },

    {
      label: 'Bouldering',
      value: 'Bouldering',
      equipment: [
        'Chalk and Chalk Bag',
        'Crash Pad',
        'Daypack',
        'First Aid Kit',
        'Helmet',
        'Rock Shoes',
        'Route Description or Guidebook',
      ],
    },

    {
      label: 'Hiking ',
      value: 'Hiking',
      equipment: ['Daypack', 'Route Description or Guidebook'],
    },

    {
      label: 'Ice Climbing ',
      value: 'Ice Climbing',
      equipment: [
        'Anchor Material',
        'Avalanche Gear- beacon, shovel probe',
        'Backpack',
        'Belay Device',
        'Climbing Harness with Ice Clippers',
        'Climbing Helmet',
        'Communication Device',
        'First Aid Kit',
        'Headlamp',
        'Ice Screw and Draws',
        'Ice Tools',
        'Locking Carabiners',
        'Mountaineering Boots',
        'Small Knife',
        'Spare locking carabiner',
        'Spare Tat',
        'Tarp',
        'Technical Crampons',
        'V Threader',
      ],
    },

    {
      label: 'Indoor Gym',
      value: 'Indoor Gym',
      equipment: [
        'Belay Glasses',
        'Belay/ Rappel Device',
        'Chalk and Chalk Bag',
        'Locking Carabiners',
        'Harness',
        'Helmet',
        'Personal Anchor System',
        'Quickdraws',
        'Rock Shoes',
        'Rope',
      ],
    },

    {
      label: 'Sport & Top Rope Climbing ',
      value: 'Sport & Top Rope Climbing',
      equipment: [
        'Belay Glasses',
        'Belay/ Rappel Device',
        'Chalk and Chalk Bag',
        'Daypack',
        'First Aid Kit',
        'Harness',
        'Helmet',
        'Locking Carabiners',
        'Non Locking Carabiners',
        'Personal Anchor System',
        'Quickdraws',
        'Rock Shoes',
        'Rope',
        'Rope Bag',
        'Route Description or Guidebook',
        'Sewn Runners',
      ],
    },

    {
      label: 'Trad Climbing ',
      value: 'Trad Climbing',
      equipment: [
        'Belay Glasses',
        'Belay/ Rappel Device',
        'Cams #',
        'Chalk and Chalk Bag',
        'Daypack',
        'First Aid Kit',
        'Gear Sling',
        'Harness',
        'Helmet',
        'Hexes#',
        'Locking Carabiners',
        'Non Locking Carabiners',
        'Nuts #',
        'Nut tool',
        'Personal Anchor System',
        'Quickdraws',
        'Rock Shoes',
        'Rope',
        'Rope Bag',
        'Route Description or Guidebook',
        'Sewn Runners',
      ],
    },
  ],

  ClimbTypeNames: [
    { label: 'Biking', value: 'Biking' },
    { label: 'Bouldering', value: 'Bouldering' },
    { label: 'Hiking', value: 'Hiking' },
    { label: 'Ice Climbing', value: 'Ice Climbing' },
    { label: 'Indoor Gym', value: 'Indoor Gym' },
    {
      label: 'Sport and Top Rope Climbing',
      value: 'Sport and Top Rope Climbing',
    },
    { label: 'Trad Climbing', value: 'Trad Climbing' },
  ],

  DificultyLevel: [
    { label: 'New Climber', value: 'New Climber' },
    { label: 'Beginner  - up to 5.7', value: 'Beginner  - up to 5.7' },
    {
      label: 'Intermediate  - up to 5.10+',
      value: 'Intermediate  - up to 5.10+',
    },
    { label: 'Advanced - 5.10+ and up', value: 'Advanced - 5.10+ and up' },
  ],

  DificultyLevel1: [
    { label: 'All Levels', value: 'All Levels' },
    { label: 'New Climber', value: 'New Climber' },
    { label: 'Beginner  - up to 5.7', value: 'Beginner  - up to 5.7' },
    {
      label: 'Intermediate  - up to 5.10+',
      value: 'Intermediate  - up to 5.10+',
    },
    { label: 'Advanced - 5.10+ and up', value: 'Advanced - 5.10+ and up' },
  ],
  DificultyLevelExpedition: [
    { label: 'All Levels', value: 'All Levels' },

    { label: 'New Climber', value: 'New Climber' },
    { label: 'Beginner  - up to 5.7', value: 'Beginner  - up to 5.7' },
    {
      label: 'Intermediate  - up to 5.10+',
      value: 'Intermediate  - up to 5.10+',
    },
    { label: 'Advanced - 5.10+ and up', value: 'Advanced - 5.10+ and up' },
  ],
  PersonalEquipments: [
    'Anchor Material',
    'Avalanche Gear- beacon, shovel probe',
    'Backpack',
    'Belay Device',
    'Belay Glasses',
    'Belay/ Rappel Device',
    'Bike',
    'Cams #',
    'Climbing Harness with Ice Clippers',
    'Climbing Helmet',
    'Chalk and Chalk Bag',
    'Communication Device',
    'Crash Pad',
    'Daypack',
    'First Aid Kit',
    'Gear Sling',
    'Gloves',
    'Harness',
    'Headlamp',
    'Helmet',
    'Hexes#',
    'Hydration',
    'Ice Screw and Draws',
    'Ice Tools',
    'Locking Carabiners',
    'Mountaineering Boots',
    'Multi Tool',
    'Non Locking Carabiners',
    'Nuts #',
    'Nut tool',
    'Personal Anchor System',
    'Pump',
    'Quickdraws',
    'Rock Shoes',
    'Rope',
    'Rope Bag',
    'Route Description or Guidebook',
    'Shoes',
    'Small Knife',
    'Spare Locking Carabiner',
    'Spare Tat',
    'Sewn Runners',
    'Tarp',
    'Technical Crampons',
    'V Threader',
  ],
};
