import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentUser, isProfileComplete } from '../store/atoms';
const {useEffect,useState}=React;
 
export const useIsProfileComplete = ()=>{
    const cUser = useRecoilValue(currentUser);
    const [profileComplete, setProfileComplete] = useRecoilState(isProfileComplete);

    const checkProfile = () => {
        let completed = true;
    
        if (!cUser.firstName) {
          completed = false;
        }
        if (!cUser.lastName) {
          completed = false;
        }
        if (!cUser.personalEquipment) {
          completed = false;
        }
        if (!cUser.sharedEquipment) {
          completed = false;
        }
        if (!cUser.yearsClimbing) {
          completed = false;
        }
        if (!cUser.expLevel) {
          completed = false;
        }
        if (!cUser.preferedClimbing) {
          completed = false;
        }
        setProfileComplete(completed);
        //console.log('completed: ', profileComplete);
        return completed;
    }
 
    useEffect(()=>{
      if (cUser.uid) { checkProfile(); }
    },
    [cUser]);

    return {checkProfile,profileComplete};
}