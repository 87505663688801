import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  Divider,
  Button,
  LinearProgress
} from '@material-ui/core';

import { Firestore } from '../../constants/Firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 15,
    width: '75%'
  },
  details: {
    display: 'flex',
    marginLeft:'25%',
    marginTop:20,
  },
  avatar: {
    marginLeft: '25%',
    marginTop:20,
    height: 210,
    width: 200,
    flexShrink: 0,
    flexGrow: 0
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  progress: {
    //marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    color: 'white',
    width:150,
    borderRadius: 8,
  },
  locationText: {}
}));

const ReportView = (props:any) => {
  const { className, deleteReport, report, ...rest } = props;
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {report && <>
      <CardHeader
        className={classes.cardHeader}
        title={report.userName}
        titleTypographyProps={{variant:'body1'}}
      />
      <CardContent>
        <div className={classes.details}>
          <Typography >{report.report}</Typography>
          <LinearProgress
            value={70}
            variant="determinate"
          />
        </div>
        {report.image && <Avatar
            className={classes.avatar}
            variant={'square'}
            src={report.image}
          />}
      </CardContent>
      <Divider />
      <CardActions style={{justifyContent:'flex-end'}}>
        <Button
          className={classes.uploadButton}
          onClick={()=>deleteReport(report)}
          variant="contained"
        >
          Delete
        </Button>
      </CardActions>
      </>}
    </Card>
  );
};

ReportView.propTypes = {
  businessData: PropTypes.any,
  className: PropTypes.string
};

export default React.memo(ReportView);
