import * as yup from 'yup';

export const signUpSchema = yup.object().shape({
  userName: yup.string().required('Required'),
  email: yup.string().email('Invalid email').required('Required'),

  password: yup
    .string()
    .required('Required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
});

export const signInSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Required'),
  password: yup.string().required('Required'),
});

export const addExcursionSchema = yup.object().shape({
  location: yup.string().required('Required'),
  // date: yup.date().required('Required'),
  type: yup.object().required('Required'),
  name: yup.string().required('Required'),
  slots: yup.number().required('Required'),
  //ownerInfo: yup.string(),
  ownerId: yup.string().required('Required'),
  difficulty: yup.string().required('Required'),
  image: yup.string(),
  //crag: yup.string(),
  // friendsOnly: yup.bool(),
});

export const addFriendSchema = yup
  .object()
  .shape({ name: yup.string().required('Required') });

export const editProfileSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  //age: yup.string().required('Required'), //.positive().integer(),
  //email: yup.string().email('Invalid email'),
  //yearsClimbing: yup.number().positive().integer(),
  //expLevel: yup.string(),//.positive().integer(),
  //goals: yup.string(),
  //accomplishments: yup.string(),
  //interest: yup.string(),
  travelDistance: yup.number(),
  // covidCare: yup.array().of(yup.object()),
  //preferedClimbing: yup.array().of(yup.string()),
  //sharedEquipment: yup.array().of(yup.string()),
  //personalEquipment: yup.array().of(yup.string()),
});

export const reportSchema = yup.object().shape({
  report: yup.string().required('Required'),
});

export const addClimbTypeSchema = yup.object().shape({
  type: yup.string().required('Required'),
});

export const addEquipmentSchema = yup.object().shape({
  type: yup.string().required('Required'),
  climb: yup.string().required('Required'),
});

export const contactSchema = yup.object().shape({
  subject: yup.string().required('Required'),
  body: yup.string().required('Required'),
});
