import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { Formik } from 'formik';
import { addClimbTypeSchema } from '../constants/Schemas';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Firestore } from '../constants/Firebase';
import Page from '../components/Page';
import { useGetAllUsers } from '../hooks/useAllUsers';
import Strings from '../constants/Strings';
import { ClimberProfile } from '../../types';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    borderRadius: 15,
  },
  paper: {
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  iconBtn: {
    borderRadius: 10,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
    width: 200,
    height: 200,
    objectFit: 'contain',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const formValues = {
  type: '',
};
export default function climbTypePage() {
  const classes = useStyles();
  const ref = Firestore.collection('ClimbTypes').doc('ClimbTypes');
  const equipRef = Firestore.collection('Equipment');
  const [types, setTypes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const addClimbTypes = async (type: string) => {
    let newtypes = [...types, type];
    //newtypes.push(type);
    ref.update({ ClimbTypes: newtypes });
    setTypes(newtypes);
    await Firestore.collection('Equipment')
      .doc(type)
      .get()
      .then((snapShot) => {
        console.log('in then',type);
        if (!snapShot.exists) {
          //console.log('in if');

          Firestore.collection('Equipment')
            .doc(type)
            .set({ label: type, value: type, type: type, equipment: [] });
        }
        handleClose();
      });
  };
  const removeClimbTypes = async (type: string) => {
    let newtypes = [...types];
    newtypes = newtypes.filter((item) => item != type);
    ref.update({ ClimbTypes: newtypes });
    equipRef.doc(type).delete().catch();
    setTypes(newtypes);
  };
  const getClimbTypes = async () => {
    setLoading(true);
    ref
      .get()
      .then((types: any) => {
        //console.log(types.data().ClimbTypes);
        setTypes(types.data().ClimbTypes.sort());
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getClimbTypes();
  }, []);

  const RenderTypes = () => {
    return (
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell align='left'>Name</TableCell>
            <TableCell align='right'>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell align='center' colSpan={3}>
                <CircularProgress color='primary' />
              </TableCell>
            </TableRow>
          ) : (
            types.map((type, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align='left'>{type}</TableCell>
                  <TableCell align='right'>
                    <Button
                      variant='contained'
                      color='primary'
                      size={'small'}
                      onClick={() => {
                        removeClimbTypes(type);
                      }}
                    >
                      remove
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    );
  };

  return (
    <Page title='Dashboard' layout='dashboard'>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={'Climb Types'}
          titleTypographyProps={{variant:'body1'}}
          action={
            <Button
              variant="contained"
              color="primary"
              className={classes.iconBtn}
              size={'small'}
              onClick={handleClickOpen}
            >
              <AddIcon style={{color:'white'}} />
            </Button>
          }
        />
        <CardContent className={classes.paper}>
          <RenderTypes />
        </CardContent>  
      </Card>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography={true} id="form-dialog-title">Add Climb Type</DialogTitle>
        <Formik
        initialValues={formValues}
        validationSchema={addClimbTypeSchema}
        onSubmit={(values, actions) => {
          addClimbTypes(values.type);
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setSubmitting,
          setFieldError,
          isSubmitting,
          values,
          errors,
        }) => (
          <>
            <DialogContent className={classes.form}>
            <TextField
              margin='dense'
              required
              fullWidth
              id='type'
              label='Add a Climb Type'
              name='type'
              autoComplete='type'
              autoFocus
              value={values.type}
              onChange={handleChange('type')}
              error={errors.type ? true : false}
            />
            <Typography variant={'subtitle2'} color={'error'}>
              {errors.type}
            </Typography>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.submit}
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                ADD
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
      </Dialog>
    </Page>
  );
}
