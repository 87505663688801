import React from 'react';
import { Image, ImageBackground, Platform } from 'react-native';
import { useTheme } from 'react-native-paper';
import { Text, View } from './Themed';

const Logo = Platform.OS == 'web'? '' : require('../../assets/images/splash.png');
interface SplashProps{
    loadMessage:string
}
export default function SplashScreen({loadMessage}:SplashProps){ 
    const theme = useTheme();
    return(
        <ImageBackground source={Logo} style={{flex:1,alignItems:'center',justifyContent:'center'}}>
            {Platform.OS == 'web' &&
                <Image source={require('../assets/logo.png')} resizeMode={'contain'} style={{width:'40%',height:'40%'}} />
            }
            <Text 
                style={{
                    marginTop: Platform.OS == 'web'? 25 : '110%',
                    textAlign: 'center',
                    color:'white'
                }}
            >
                {Platform.OS !== 'web' && loadMessage}
            </Text>
        </ImageBackground>
    );
}