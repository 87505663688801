import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton
} from '@material-ui/core';
import { Grid } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
//@ts-ignore
import { NavLink as RouterLink } from 'react-router-dom';
import Page from '../../components/Page';
import { StyleSheet } from 'react-native';
import { Firestore } from '../../constants/Firebase';

import Strings from '../../constants/Strings';
import ReportList from './ReportList';
import ReportView from './ReportView'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 15,
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  
}));
export default function ReportPage() {
  const classes = useStyles();
  const ref = Firestore.collection('Reports');
  const [reports, setReports] = useState([]);
  const [report,setReport] = useState(false);

  useEffect(() => {
    getReports();
  }, []);

  const getReports = async () => {
    ref.get().then((querySnapshot) => {
      const documents:any = querySnapshot.docs.map((doc) => doc.data());
      setReports(documents);
      setReport(documents[0]);
      console.log(documents);
    });
  };

  const deleteReport = async (report:any) => {
    const ref = Firestore.collection('Reports');
    ref.doc(report.id).delete()
    .then(()=>{
      setReports( reports.filter((e:any) => e.id !== report.id) );
      setReport(false);
    });
  };
  
  return (
    <Page title='Report' layout='dashboard'>
      <Grid container spacing={4}>
        <Grid item lg={4} md={6} xl={3} xs={6} >
          
        <Card
          className={classes.root}
        >
          <CardHeader
            className={classes.cardHeader}
            title="Latest Reports"
            titleTypographyProps={{variant:'body1'}}
            subtitle={`${reports.length} in total`}
          />
          <Divider />
          <CardContent className={classes.content}>
            <List>
              {reports.map((report:any, i:number) => (
                <Button
                  key={i}
                  style={{width:'100%'}}
                  //component={CustomRouterLink}
                  //to={'#'}
                  onClick={()=>{setReport(report)}}
                >
                  <ListItem
                    divider={i < reports.length - 1}
                    key={report.id}
                  >
                    <ListItemText
                      primary={report.userName}
                      //secondary={product.status}
                    />
                    {/* <IconButton
                      edge="end"
                      size="small"
                    >
                      <MoreVertIcon />
                    </IconButton> */}
                  </ListItem>
                </Button>
              ))}
            </List>
          </CardContent>
          <Divider />
        </Card>

        </Grid>  
        <Grid item lg={6} md={8} xl={5} xs={8} >
          {/* @ts-ignore */}
          <ReportView report={report} deleteReport={deleteReport} />
        </Grid>  
      </Grid>
    </Page>
  );
}