import React from 'react';
import { useRecoilValue } from 'recoil';
//@ts-ignore
import uniq from 'lodash.uniq';
import { ClimberProfile, Excursions } from '../../types';
import { Firestore } from '../constants/Firebase';
import { haversineDistance } from '../constants/Functions';
import { myLocation, currentUser } from '../store/atoms';

const { useEffect, useState } = React;
const InitialState = {
  userLoading: false,
  userList: [],
  userErr: false,
  userErrMsg: '',
};

export const useGetAllUsers = (sort = '') => {
  const [userState, setuserState] = useState(InitialState);
  let ref = Firestore.collection('Users');

  const getUsers = async () => {
    const list: any = [];
    setuserState({ ...InitialState, userLoading: true });
    if (sort) {
      //@ts-ignore
      ref = ref.orderBy(sort);
    }
    await ref
      .get()
      .then((docs) => {
        docs.forEach((data: any) => {
          if (data.exists) {
            list.push(data.data());
          }
          setuserState({ ...InitialState, userList: list });
        });
      })
      .catch((error) => {
        setuserState({
          ...InitialState,
          userErr: true,
          userErrMsg: error.message,
        });
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return { ...userState };
};

export const useGetAllUsersAdmin = (sort = '') => {
  const [userState, setuserState] = useState(InitialState);
  let ref = Firestore.collection('Users');

  const getUsers = async () => {
    let list: any = [];
    setuserState({ ...InitialState, userLoading: true });
    if (sort) {
      //@ts-ignore
      ref = ref.orderBy(sort);
    }
    await ref.onSnapshot(
      (docs) => {
        list = [];
        docs.forEach((data: any) => {
          if (data.exists) {
            list.push(data.data());
          }
          setuserState({ ...InitialState, userList: list });
        });
      },
      (error) => {
        setuserState({
          ...InitialState,
          userErr: true,
          userErrMsg: error.message,
        });
      }
    );
  };

  useEffect(() => {
    getUsers();
  }, []);

  return { ...userState };
};

const results: ClimberProfile[] = [];
const InitialSearchUsers = {
  loading: false,
  results,
  error: false,
  errorMessage: '',
};
export const useSearchUsers = (explore = false) => {
  const currentLocation: any = useRecoilValue(myLocation);
  const user: ClimberProfile = useRecoilValue(currentUser);
  const [state, setState] = useState(InitialSearchUsers);
  const ref = Firestore.collection('Users');

  //TODO: temp solution, thinking about making this a cloud function
  const SearchUsers = async (text: string) => {
    if (!text) {
      setState({ ...InitialSearchUsers });
      return;
    }
    text = text.toLowerCase();
    setState({ ...InitialSearchUsers, loading: true });
    let list: ClimberProfile[] = [];
    return ref
      .where('name', '>=', text)
      .where('name', '<', text + 'z')
      .get()
      .then((docs) => {
        docs.forEach((data: any) => {
          if (data.exists) {
            list.push(data.data());
          }
        });
      })
      .then(() => {
        list = uniq(list);
        console.log(list);
        setState({ ...InitialSearchUsers, results: list });
      })
      .catch((error) => {
        console.log('searchErr: ', error.message);
        setState({
          ...InitialSearchUsers,
          error: true,
          errorMessage: error.message,
        });
      });
  };
  interface searchFilterProps {
    level?: string;
    typeLevel?: object;
    type?: Array<string>;
    distance?: number;
    friend?: boolean;
  }
  const SearchFilter = async ({
    level,
    typeLevel,
    type,
    distance,
    friend,
  }: searchFilterProps) => {
    setState({ ...InitialSearchUsers, loading: true });
    let list: ClimberProfile[] = [];
    let searchRef = ref;
    let result;
    if (level) {
      //@ts-ignore
      searchRef = searchRef.where('expLevel', '==', level);
    }

    if (type && type.length > 0) {
      let idList: any = [];
      //@ts-ignore
      result = await searchRef
        .where('preferedClimbing', 'array-contains-any', type)
        .get()
        .then((docs) => {
          docs.forEach((data: any) => {
            if (!data.exists) {
              return;
            }
            for (const [key, value] of Object.entries(typeLevel ?? '')) {
              if (data.data().preferedClimbing.includes(key)) {
                if (
                  value == 'All Levels' &&
                  !idList.includes(data.data().uid)
                ) {
                  // console.log(data.data());

                  idList.push(data.data().uid);
                  list.push(data.data());
                } else if (
                  data.data().climbLevel[key] == value &&
                  !idList.includes(data.data().uid)
                ) {
                  idList.push(data.data().uid);
                  list.push(data.data());
                }
              }
            }
          });
        })
        .then(() => {
          if (!distance) return;
          let newList: any = [];
          for (let i = 0; i < list.length; i++) {
            const mine = currentLocation?.coords ?? {
              latitude: 0,
              longitude: 0,
            };
            const person: ClimberProfile = list[i];
            const location =
              person.location && person.location.lat
                ? {
                    latitude: person.location.lat,
                    longitude: person.location.lon,
                  }
                : { latitude: 0, longitude: 0 };
            const calDistance = haversineDistance(
              { latitude: mine.latitude, longitude: mine.longitude },
              location
            );
            //console.log('calDistance: ', calDistance);
            if (calDistance > 0 && calDistance <= distance) {
              newList.push(person);
            }
          }
          list = newList;
        })
        .then(() => {
          if (explore) {
            list = list.filter((item: any) => item.location != false);
          }
          if (friend) {
            list = list.filter((item: any) =>
              user.friendList.includes(item.uid)
            );
          }
          setState({ ...InitialSearchUsers, results: list });
        });
      return;
      //console.log('--------------------------------------------------');
      //console.log(list);
    }
    if (distance || level)
      return searchRef
        .get()
        .then((docs) => {
          docs.forEach((data: any) => {
            if (!data.exists) {
              return;
            }
            if (!distance) list.push(data.data());
            else {
              const mine = currentLocation?.coords ?? {
                latitude: 0,
                longitude: 0,
              };
              const person: ClimberProfile = data.data();
              const location =
                person.location && person.location.lat
                  ? {
                      latitude: person.location.lat,
                      longitude: person.location.lon,
                    }
                  : { latitude: 0, longitude: 0 };
              const calDistance = haversineDistance(
                { latitude: mine.latitude, longitude: mine.longitude },
                location
              );
              //console.log('calDistance: ', calDistance);
              if (calDistance > 0 && calDistance <= distance) {
                list.push(data.data());
              }
            }
          });
        })
        .then(() => {
          if (explore) {
            list = list.filter((item: any) => item.location != false);
          }
          if (friend) {
            list = list.filter((item: any) =>
              user.friendList.includes(item.uid)
            );
          }
          setState({ ...InitialSearchUsers, results: list });
        })
        .catch((error) => {
          console.log('searchErr: ', error.message);
          setState({
            ...InitialSearchUsers,
            error: true,
            errorMessage: error.message,
          });
        });
    else {
      if (explore) {
        list = list.filter((item: any) => item.location != false);
      }
      if (friend) {
        list = list.filter((item: any) => user.friendList.includes(item.uid));
      }
      setState({ ...InitialSearchUsers, results: list });
    }
    console.log('the end----------------------------------');
    console.log(list);
  };
  return { ...state, SearchFilter, SearchUsers };
};
