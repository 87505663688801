import { Linking, Platform } from "react-native";

import { FirebaseAuth } from '../constants/Firebase';

// hav(theta) = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLon - aLon)
type haversineDistanceParams = {latitude:number, longitude:number}

export function haversineDistance(a:haversineDistanceParams, b:haversineDistanceParams):number {
  const asin = Math.asin
  const cos = Math.cos
  const sin = Math.sin
  const sqrt = Math.sqrt
  const PI = Math.PI
    
  // equatorial mean radius of Earth (in meters)
  const R = 6378137
    
  function squared (x:number) { return x * x }
  function toRad (x:number) { return x * PI / 180.0 }
  function hav (x:number) {
      return squared(sin(x / 2))
  }  
  const aLat = toRad(a.latitude)
  const bLat = toRad(b.latitude)
  const aLng = toRad(a.longitude)
  const bLng = toRad(b.longitude)

  const ht = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLng - aLng)
  return ( 2 * R * asin(sqrt(ht)) ) * 0.0006213712
}

export function findMissingGear(expGear:[string],userGear:string[]){
  let missing = expGear.filter(x => userGear.indexOf(x) === -1);
  return missing.toString();
}

export const linkToMaps = ({lat,lon}:{lat:number,lon:number}, name:string)=>{
  const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
  const latLng = `${lat},${lon}`;
  const label = name;
  const url = Platform.select({
    ios: `${scheme}${label}@${latLng}`,
    android: `${scheme}${latLng}(${label})`
  });
  Linking.openURL(url ?? '').catch((error)=>{alert('We\'re unable to open your map application at this time.')}); 
}

export const logout = (setUser:any) => {
  FirebaseAuth.signOut().then(() => {
    setUser({});
  })
  .catch((error)=>{
    console.log('error: ', error.message);
  });
};