import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Page from '../components/Page';
import { Firestore } from '../constants/Firebase';

const useStyles = makeStyles((theme) => ({
    container: {
      marginTop: theme.spacing(4),
      borderRadius: 15,
    },
  }));

export default function TermsPage(){ 
    const classes = useStyles();
    const ref = Firestore.collection('docs').doc('termsandconditions');
    const [policy,setPolicy] = React.useState({date:new Date(),text:''});

    React.useEffect(()=>{
        ref.get()
        .then((snapshot:any)=>{
            setPolicy({
                date: new Date( snapshot.data().date ),
                text: snapshot.data().text
            });
        })
        .catch((error)=>{
            console.log('error',error.message);
            setPolicy({date:new Date(),text:'An error loading Terms and Contitions occurred.'});
        });
    },[policy]);
  
    return(
        <Page title={'Terms and Conditions'}>
            <Container className={classes.container} maxWidth="md">
                <div dangerouslySetInnerHTML={{__html: policy.text}} />
            </Container>
        </Page>
    )
}