import React from 'react';
import { NotificationProps, NotificationTypes } from '../../types';
import { Firestore } from '../constants/Firebase';
import { currentUser, reqNotification } from '../store/atoms';
import { useRecoilState, useRecoilValue } from 'recoil';

export const sendPushNotification = async (
  expoPushToken:string,
  title: string,
  body: string,
  data: any,
  userId?: string, //id of the user the notification is intended for
  notificationType?: NotificationTypes,
  eventId?: string, //id of the notification item
) => {
  const message = {
    to: expoPushToken,
    sound: 'default',
    title: title,
    body: body,
    data: { someData: data },
  };

  userId = userId ?? undefined;
  notificationType = notificationType ?? undefined;
  eventId = eventId ?? undefined;
  AddNotification({title,body,data,userId,notificationType,eventId});

  await fetch('https://exp.host/--/api/v2/push/send', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Accept-encoding': 'gzip, deflate',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  })
  .catch((error)=>{
    console.log('sendNotifErr',error.message);
  });
};

interface AddNotificationProps {
  title: string,
  body: string,
  data: any,
  userId?: string, //id of the user the notification is intended for
  notificationType?: NotificationTypes,
  eventId?: string, //id of the notification item
}
export const AddNotification = ({title,body,data,userId,notificationType,eventId}:AddNotificationProps)=>{
  if( !userId || !notificationType || !eventId){ return;}

  const notifData = {
    id:eventId,
    type: notificationType,
    title,
    body,
    data,
    userId
  };

  Firestore.collection('Notifications').doc(userId).collection(notificationType).doc(eventId)
    .set(notifData)
    .catch((error)=>{
      console.log('addNotificationErr',error.message);
  })

  Firestore.collection('Notifications').doc(userId).collection('notifications').doc(eventId)
    .set(notifData)
    .catch((error)=>{
      console.log('addNotificationErr',error.message);
  })
}

interface DeleteNotificationProps {
  userId: string,
  notificationType: NotificationTypes,
  eventId: string
}
export const DeleteNotification = ({userId,notificationType,eventId}:DeleteNotificationProps)=> {
  Firestore.collection('Notifications').doc(userId).collection(notificationType).doc(eventId)
  .delete()
  .catch((error)=>{
    console.log('deleteNotificationErr',error.message);
  })
  Firestore.collection('Notifications').doc(userId).collection('notifications').doc(eventId)
  .delete()
  .catch((error)=>{
    console.log('deleteNotificationErr',error.message);
  })
}

interface GetNotificationsProp {
  loading: boolean,
  notifications: NotificationProps[],
  error: boolean,
  errorMsg: string,
}
const InitialNotifications = {loading:false,notifications:[],error:false,errorMsg: '',}
export const useGetNotifications = (userId:string,type:NotificationTypes | 'all')=>{
  const user = useRecoilValue(currentUser);
  const [state,setState] = React.useState<GetNotificationsProp>(InitialNotifications);
  const [reqNotif, setreqNotif] = useRecoilState(reqNotification);

  const GetNotifications = (type:NotificationTypes | 'all')=>{
    let Ref = Firestore.collection('Notifications').doc(userId);
    if(type == 'all') {
      //@ts-ignore
      Ref = Ref.collection('notifications');
    }
    else {
      //@ts-ignore
      Ref = Ref.collection(type);
    }

    return Ref.onSnapshot((snapshot:any)=>{
      const notifications: NotificationProps[] = [];
      snapshot.forEach((doc:any) => {
        notifications.push(doc.data());
      });
      setState({...InitialNotifications,notifications});
      if(type == 'ExpRequest') {
        //@ts-ignore
        setreqNotif(notifications);
      }
    },
    (error)=>{
      console.log('getNotifErr',error.message);
      setState({...InitialNotifications,error:false,errorMsg:error.message});
    })
  }

  React.useEffect(()=>{
    if(!user.uid || !userId || !type) return;
    setState({...InitialNotifications,loading:true});
    const unSubscribe = GetNotifications(type);

    //return unSubscribe();
  },[user,userId,type]);

  return {...state};
}
