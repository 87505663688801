import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';


interface LoadingModalType {
    visible: boolean
}

export default function LoadingModal({visible}:LoadingModalType){ 
    const classes = useStyles();

    const handleClose = ()=>{

    }

    return(
        <Backdrop className={classes.backdrop} open={visible} onClick={handleClose}>
        <CircularProgress color="primary" />
      </Backdrop>
    )
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));