import React from 'react';
//@ts-ignore
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Hidden,
  Typography,
  Link,
  makeStyles,
} from '@material-ui/core';
import { expo } from '../../../app.json';

const Logo = require('../../assets/logo.png');

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    height: 64,
  },
  logo: {
    marginRight: theme.spacing(2),
    width: 50,
    height: 50,
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({ ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.root} color='default' {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to='/'>
          <img alt='Logo' src={Logo} className={classes.logo} />
        </RouterLink>
        {/* <Hidden mdDown>
          <Typography variant='caption' color='textSecondary'>
            Version {expo.version}
          </Typography>
        </Hidden> */}
        <Box flexGrow={1} />
        {/* <Link
          className={classes.link}
          color='textSecondary'
          component={RouterLink}
          to='/login'
          underline='none'
          variant='body2'
        >
          Dashboard
        </Link> */}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
