import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Page from '../components/Page';
import { Firestore } from '../constants/Firebase';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Editor } from '@tinymce/tinymce-react';


const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    borderRadius: 15,
  },
  paper: {
    //marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  iconBtn: {
    borderRadius: 10,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
    width: 200,
    height: 200,
    objectFit: 'contain',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function EditTermsPage() {
  const classes = useStyles();
  const editorRef = React.useRef<any>(null);
  const ref = Firestore.collection('docs').doc('termsandconditions');
  const [policy,setPolicy] = React.useState({date:new Date(),text:''});

  React.useEffect(()=>{
    ref.get()
    .then((snapshot:any)=>{
      setPolicy({
        date: new Date( snapshot.data().date ),
        text: snapshot.data().text
      });
    })
    .catch((error)=>{
      console.log('error',error.message);
      setPolicy({date:new Date(),text:'An error loading Terms and Contitions occurred.'});
    });
  },[]);

  const formValues = {
    text: 'value',
  };
  
  return (
    <Page title='Terms & Conditions' layout='dashboard'>
      <Formik
        initialValues={formValues}
        //validationSchema={addEquipmentSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          let text = '';
          if (editorRef.current) {
            //console.log(editorRef.current.getContent());
            text = editorRef.current.getContent();
          }
          ref.update({
            date: new Date(),
            text
          })
          .then(()=>{
            actions.setSubmitting(false);
            alert('Saved successfully.');
          })
          .catch((error)=>{
            actions.setSubmitting(false);
            alert(error.message);
          })
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setSubmitting,
          setFieldError,
          setFieldValue,
          isSubmitting,
          values,
          errors,
        }) => (
          <>
          <Card className={classes.card}>
            <CardHeader
              className={classes.cardHeader}
              title={
                <Typography>Terms & Conditions</Typography>
              }
              titleTypographyProps={{variant:'body1'}}
            />
            <CardContent className={classes.paper}>
              <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={policy.text}
                init={{
                  height: 500,
                  width:'100%',
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
              <Button 
                onClick={()=>handleSubmit()}
                variant='contained'
                color='primary'
                size={'small'}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </CardContent>
          </Card>
          
          </>
        )}
      </Formik>
    </Page>
  );
}
