import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ClimberProfile, MessageThreadType } from '../../types';
import { FirebaseAuth, Firestore } from '../constants/Firebase';
import { currentUser, msgCount } from '../store/atoms';
const { useEffect, useState } = React;

interface ChatListType {
  loading: boolean;
  threads: MessageThreadType[];
  error: boolean;
  errMsg: string;
}
const InitialChatList = {
  loading: false,
  threads: [],
  error: false,
  errMsg: '',
};
export const useChatList = (group: boolean) => {
  const [state, setState] = useState<ChatListType>(InitialChatList);
  const currentuser: ClimberProfile = useRecoilValue(currentUser);

  useEffect(() => {
    setState({ ...InitialChatList, loading: true });
    const unsubscribe = Firestore.collection('MESSAGE_THREADS')
      .orderBy('latestMessage.createdAt', 'desc')
      .where('participants', 'array-contains', currentuser.uid) // did not work for some reason
      .where('group', '==', group)
      .onSnapshot((querySnapshot) => {
        const threads: MessageThreadType[] = querySnapshot.docs.map(
          (documentSnapshot: any) => {
            const t: MessageThreadType = {
              ...documentSnapshot.data(),
              _id: documentSnapshot.id,
            };
            console.log(t);

            return t;
          }
        );
        setState({ ...InitialChatList, threads });
      });

    return () => unsubscribe();
  }, []);

  return { ...state };
};

interface ChatCount {
  loading: boolean;
  counts: any;
  error: boolean;
  errMsg: string;
}
const InitialChatCount = {
  loading: false,
  counts: {},
  error: false,
  errMsg: '',
};
export const useChatCount = () => {
  const [state, setState] = useState<ChatCount>(InitialChatCount);
  const [count, setCount] = useRecoilState(msgCount);
  const currentuser: ClimberProfile = useRecoilValue(currentUser);

  useEffect(() => {}, []);

  const getCounts = (uid: string) => {
    const unsubscribe = Firestore.collection('MessageCount')
      .doc(uid)
      .onSnapshot((querySnapshot) => {
        const counts: any = querySnapshot.data();
        setCount(counts);
        setState({ ...InitialChatCount, counts });
      });
    return () => unsubscribe();
  };

  return { ...state, getCounts };
};

export const useChat = () => {
  useEffect(() => {}, []);
};

export const useSingleChat = () => {
  useEffect(() => {}, []);
};

export const useFriendList = () => {
  useEffect(() => {}, []);
};
