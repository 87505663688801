import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import MailOutline from '@material-ui/icons/MailOutline';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { Formik } from 'formik';
import { useSetRecoilState } from 'recoil';

import { signInSchema } from '../constants/Schemas';
import Page from '../components/Page';
import { FirebaseAuth, Firestore } from '../constants/Firebase';
import { uid } from '../store/atoms';
import { InputAdornment } from '@material-ui/core';

const Logo = require('../assets/logo.svg');
const WebBackgroundImage = require('../assets/webBackground.webp');

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: 'url(' + WebBackgroundImage + ')',
    width: '100%',
    height: '100%',
    display:'flex',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  paper: {
    marginTop: theme.spacing(25),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 450,
    height:400,
    borderRadius: 10,
  },
  cardHeader: {
    width: 450,
    height:90,
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:theme.palette.primary.main,
    
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
    width: 80,
    height: 80,
    objectFit: 'contain',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  textField: {
    borderRadius: 25,
  },
  cardActions: {
    display:'flex',
    justifyContent:'center'
  },
  submit: {
    margin: theme.spacing(3, 0, -1),
    borderRadius: 5,
    width:150
  },
}));

const formValues = {
  email: '',
  password: '',
};

export default function LoginPage() {
  const classes = useStyles();
  const setUid = useSetRecoilState(uid);
  const ref = Firestore.collection('Users');

  const handlePasswordReset = async (email:string,setSubmitting:(l:boolean)=>void,setFieldError:(field:string,error:string)=>void) => {
    setSubmitting(true);
    FirebaseAuth.sendPasswordResetEmail(email)
    .then(()=>{
      alert('Password reset email sent successfully');
    })
    .catch((error)=>{
      setFieldError('email', 'The entered email address is invalid.');
    })
    .finally(()=>{
      setSubmitting(false);
    });
  }
  
  const login = async (values:{email:string,password:string},setSubmitting:(l:boolean)=>void,setFieldError:(field:string,error:string)=>void) => {
    const {email, password} = values;
    setSubmitting(true);
    FirebaseAuth.signInWithEmailAndPassword(email, password).then((token) => {
      //console.log(token.user?.uid);
      ref
        .doc(token.user?.uid)
        .get()
        .then((user:any) => {
          if (user.data().status) {
            if (user.data().status !== 'admin') {
              //console.log('signout');
              FirebaseAuth.signOut().then(() => {});
              setFieldError('email', 'Invalid sign in credentials.');
            }
            else {
              //successful login
              //setUid(token.user?.uid || '');
            }
          } 
          else {
            //console.log('signout');
            FirebaseAuth.signOut().then(() => {});
            setFieldError('email', 'Invalid sign in credentials.');
          }
        })
    })
    .catch(() => {
      setFieldError('email', 'Invalid sign in credentials.');
      setFieldError('password', '');
    })
    .finally(()=>{
      setSubmitting(false);
    });
  };
  return (
    <Page className={classes.root} layout='none' title='Login'>
      <>
      <CssBaseline />
      <Card className={classes.paper}>
        <div className={classes.cardHeader}>
          <img className={classes.avatar} src={Logo} />
        </div>
        <Formik
          initialValues={formValues}
          validationSchema={signInSchema}
          onSubmit={(values, actions) => {
            login(values,actions.setSubmitting,actions.setFieldError)
          }}
        >
          {({handleChange,handleBlur,handleSubmit,setSubmitting,setFieldError,isSubmitting,values,errors}) => (
          <CardContent className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutline color={!errors.email? 'disabled' : 'error'} />
                  </InputAdornment>
                ),
              }}
              className={classes.textField}
              value={values.email}
              onChange={handleChange('email')}
              error={errors.email ? true : false}
            />
            <Typography variant={'subtitle2'} color={'error'}>{errors.email}</Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined color={!errors.password? 'disabled' : 'error'} />
                  </InputAdornment>
                ),
              }}
              className={classes.textField}
              value={values.password}
              onChange={handleChange('password')}
              error={errors.password ? true : false}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  // Do code here
                  ev.preventDefault();
                  handleSubmit();
                }
              }}
            />
            <Typography variant={'subtitle2'} color={'error'}>{errors.password}</Typography>
            <CardActions className={classes.cardActions}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                Sign In
              </Button>
              </CardActions>  
          </CardContent>
          )}
        </Formik>  
      </Card>
    </>
    </Page>
  );
}