import React, { Component } from 'react';
//@ts-ignore
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';

//routes
import HomePage from '../pages/Home';
import AdminPage from '../pages/AdminPage';
import ReportPage from '../pages/ReportPage';
import LoginPage from '../pages/LoginPage';
import EquipmentPage from '../pages/EquipmentPage';
import ClimbTypePage from '../pages/ClimbTypePage';
import PrivacyPage from '../pages/PrivacyPage';
import TermsPage from '../pages/TermsPage';
import useCachedResources from '../hooks/useCachedResources';
import SplashScreen from '../components/Splashscreen';
import { Platform } from 'react-native';
import Strings from '../constants/Strings';
import EditTermsPage from '../pages/EditTermsPage';
import EditPrivacyPage from '../pages/EditPrivacyPage';

export default function Routes() {
  const { isLoadingComplete, loadMessage, cUser } = useCachedResources();

  if (!isLoadingComplete) return <SplashScreen loadMessage={loadMessage} />;

  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact component={HomePage} />
        <Route path='/home' exact component={HomePage} />
        <Route path='/privacypolicy' exact component={PrivacyPage} />
        <Route path='/termsandconditions' exact component={TermsPage} />
        <Route path='/excursiondetails' exact component={ExcursionDetails} />
        <Route path='/download' exact component={Download} />
        <Route path='/404' exact component={NotFound} />
        <AuthenticatedRoute
          authenticated={cUser.uid ? true : false}
          path='/login'
          exact={true}
          Component={LoginPage}
        />
        <AuthenticatedRoute
          authenticated={cUser.uid ? true : false}
          path='/admin'
          exact={true}
          Component={AdminPage}
        />
        <AuthenticatedRoute
          authenticated={cUser.uid ? true : false}
          path='/report'
          exact={true}
          Component={ReportPage}
        />
        <AuthenticatedRoute
          authenticated={cUser.uid ? true : false}
          path='/equipment'
          exact={true}
          Component={EquipmentPage}
        />
        <AuthenticatedRoute
          authenticated={cUser.uid ? true : false}
          path='/climbtypes'
          exact={true}
          Component={ClimbTypePage}
        />
        <AuthenticatedRoute
          authenticated={cUser.uid ? true : false}
          path='/editterms'
          exact={true}
          Component={EditTermsPage}
        />
        <AuthenticatedRoute
          authenticated={cUser.uid ? true : false}
          path='/editprivacy'
          exact={true}
          Component={EditPrivacyPage}
        />
      </Switch>
    </BrowserRouter>
  );
}

interface AuthenticatedRouteProps {
  authenticated: boolean;
  Component: any;
  exact?: boolean;
  path: string;
}
const AuthenticatedRoute = (props: AuthenticatedRouteProps) => {
  const { authenticated, Component, path } = props;
  return (
    <Route
      {...props}
      render={(props2: any) => {
        if (authenticated) {
          if (path != '/login') return <Component {...props2} {...props} />;
          else return <Redirect to='/admin' />;
        } else {
          if (path != '/login') return <Redirect to='/login' />;
          else return <Component {...props2} {...props} />;
        }
      }}
    />
  );
};

function NotFound() {
  return <div>404 Not found</div>;
}

function Download() {
  if( /Android/i.test(navigator.userAgent) ) {
    window.location.replace(Strings.Playstore);
  }
  else if( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
    window.location.replace(Strings.Appstore);
  }
  else {
    window.location.replace("/rawhtml/index.html#Download");
  }

  return <HomePage/>
}

function ExcursionDetails() {
  if(Platform.OS == 'web') {
     window.location.replace("/rawhtml/index.html#Download");
  }

  const now = new Date().valueOf();
  setTimeout(function () {
    if (new Date().valueOf() - now > 100) return;
    window.location.replace(
      Platform.OS == 'android'?
        Strings.Playstore
      : Platform.OS == 'ios'?
        Strings.Appstore
      :
        '/'
    );
  }, 25);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const path = urlParams.get('path');
  console.log('path: ', path);
  if(path) window.location.replace(path);
  else window.location.replace('/');


  return <HomePage/>
}
//http://192.168.100.209:11000/excursiondetails/?path=exp://192.168.100.209:19005/--/excursions/details?id=RLgextyL0MmcNAOkCPGF