import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as Updates from 'expo-updates';
import { FirebaseAuth, Firestore } from '../constants/Firebase';
import {
  currentUser,
  expoPushToken,
  climbTypes,
  equipment,
  reqNotification,
  myLocation,
} from '../store/atoms';
import { useLocation } from './useLocation';
import { useChatCount } from './useMessaging';
import { useGetNotifications } from './usePushNotification';
import { useIsProfileComplete } from './useIsProfileComplete';

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const [user, loading, error] = useAuthState(FirebaseAuth);
  const [done, setDone] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const { loading: locaLoading } = useLocation();
  const token = useRecoilValue(expoPushToken);
  const [cUser, setCurrentUser] = useRecoilState(currentUser);
  const [climbT, setClimbTypes] = useRecoilState(climbTypes);
  const [equip, setEquipment] = useRecoilState(equipment);
  const [loadMessage, setMsg] = React.useState('Loading');
  const [checkUpdate, setCheckUpdate] = React.useState(true);
  const { getCounts } = useChatCount();
  const { notifications } = useGetNotifications(cUser.uid, 'ExpRequest');
  const { checkProfile } = useIsProfileComplete();
  const [location, setLocation] = useRecoilState(myLocation);

  const complete = () => {
    // SplashScreen.hideAsync().then(() => {
    //   setMsg('done');
    //   setLoadingComplete(true);
    // });
    setMsg('done');
    //checkProfile();
    setLoadingComplete(true);
  };

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        //await SplashScreen.preventAutoHideAsync();
        await loadingUpdate();

        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          'space-mono': require('../../assets/fonts/SpaceMono-Regular.ttf'),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setDone(true);
      }
    }

    loadResourcesAndDataAsync().then();
  }, []);

  const pollVerification = async () => {
    const timeout = 0;
    const poll = new Promise((resolve, reject) => {
      const pollInterval = setInterval(() => {
        user?.reload();
        const provider = user?.providerData[0]?.providerId;
        const isVerified = provider == 'password' ? user?.emailVerified : true;
        //console.log('check Verifiy',isVerified);
        if (isVerified) {
          //clearInterval();
          //getUserData();
          resolve(pollInterval);
        }
        if (timeout >= 900) {
          //timeout after 15minutes
          reject('time out');
        }
      }, 1000);
    });

    poll.then(
      async function (value: any) {
        //console.log('resolved');
        getUserData();
        clearInterval(value);
        await Updates.reloadAsync();
      },
      function (error) {
        console.log('error: ', error.message);
        clearInterval();
      }
    );
  };

  const loadingUpdate = async () => {
    try {
      setMsg('Checking for updates.');
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        setMsg('Downloading updates.');
        await Updates.fetchUpdateAsync();
        setMsg('Installing updates.');
        await Updates.reloadAsync();
      }
    } catch (e) {}
  };

  const getUserData = async () => {
    if (!user) {
      complete();
      return;
    }
    getCounts(user.uid);
    setMsg('Getting your data');
    const ref = Firestore.collection('Users');
    const me = ref.doc(user.uid);
    if (token) {
      me.set(
        {
          uid: user.uid,
          pushNotificationsToken: token,
        },
        { merge: true }
      );
    }

    //console.log('user',user.providerData[0]?.providerId);
    const provider = user.providerData[0]?.providerId;
    const isVerified = provider == 'password' ? user.emailVerified : true;
    //console.log('isVerified: ', isVerified);

    if (!isVerified) {
      pollVerification();
    }

    me.get()
      .then((querySnapshot) => {
        setupUser(querySnapshot, user, me);
      })
      .then(() => {
        me.onSnapshot(
          (querySnapshot: any) => {
            setupUser(querySnapshot, user, me);
          },
          (error) => {
            console.log('userSnapErr', error.message);
          }
        );
      })
      .catch((error) => {
        console.log('userSnapErr', error.message);
      });
  };

  const setupUser = (querySnapshot: any, user: any, me: any) => {
    const provider = user.providerData[0]?.providerId;
    const isVerified = provider == 'password' ? user.emailVerified : true;
    //console.log('setup isVerified: ', isVerified);
    setMsg('Preparing your data');
    //const joined = new Date( user.metadata.creationTime ).getTime();

    if (!querySnapshot.data() || !querySnapshot.data().chatList) {
      const newUser = {
        uid: user.uid,
        chatList: [FirebaseAuth.currentUser?.uid],
        isVerified,
        joined: user.metadata.creationTime
          ? new Date(user.metadata.creationTime).getTime()
          : null,
      };
      me.set(newUser, { merge: true });
      setMsg('Almost there');
      //@ts-ignore
      setCurrentUser(newUser);
    } else {
      setMsg('Almost there');
      setCurrentUser({ ...querySnapshot.data(), isVerified });
      //setfList(querySnapshot.data().friendList);
    }
    //complete();
    setReady(true);
  };

  const getClimbTypes = async () => {
    const ref = Firestore.collection('ClimbTypes');
    await ref
      .doc('ClimbTypes')
      .get()
      .then((types: any) => {
        setClimbTypes(types.data().ClimbTypes);
      });
  };

  const getEquip = async () => {
    const ref = Firestore.collection('Equipment');
    const snapshot = await ref.get();
    let equip = snapshot.docs.map((doc) => doc.data());
    //@ts-ignore
    setEquipment(equip);
  };
  React.useEffect(() => {
    if (!isLoadingComplete && done && !loading && !locaLoading) {
      getUserData();
    }
  }, [done, isLoadingComplete, loading, locaLoading]);

  React.useEffect(() => {
    if (!loading && user) {
      getUserData();
    }
  }, [loading, user]);

  React.useEffect(() => {
    getClimbTypes();
    getEquip();
  }, []);

  React.useEffect(() => {
    if (ready && cUser.uid !== null) {
      complete();
    }
  }, [ready, cUser]);

  return { isLoadingComplete, loadMessage, cUser };
}
