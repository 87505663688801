import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import "firebase/storage";
// import 'firebase/analytics';
import { LogBox, Platform } from 'react-native';

const androidConfig = {
  apiKey: 'AIzaSyAJWgh7TJ5-icgxjV6WU4eAqKhevljABu8',
  authDomain: 'climbunity-df5fe.firebaseapp.com',
  projectId: 'climbunity-df5fe',
  storageBucket: 'climbunity-df5fe.appspot.com',
  messagingSenderId: '427044276636',
  appId: '1:427044276636:web:89f4a0316972886e2ca059',
  measurementId: 'G-RZQG4323QG',
};
const iosConfig = {
  apiKey: 'AIzaSyAJWgh7TJ5-icgxjV6WU4eAqKhevljABu8',
  authDomain: 'climbunity-df5fe.firebaseapp.com',
  projectId: 'climbunity-df5fe',
  storageBucket: 'climbunity-df5fe.appspot.com',
  messagingSenderId: '427044276636',
  appId: '1:427044276636:web:e46e4e29d3eec6b32ca059',
  measurementId: 'G-MXGQR3P9TD',
};

//made em separate to experiment with logging and analytics
const firebaseConfig = Platform.OS == 'ios' ? iosConfig : androidConfig;

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

if( Platform.OS != 'web'  ) LogBox.ignoreAllLogs(); //TODO: remember to resolve this

export const Firebase = firebase;
export const FirebaseAuth = firebase.auth();
export const Firestore = firebase.firestore();
export const FirebaseStorage = firebase.storage();
export const FieldValue = firebase.firestore.FieldValue;
