import React from 'react';
import {RecoilRoot} from "recoil";
import { ThemeProvider } from '@material-ui/core/styles';
//
import Navigation from './src/navigation.web';
import { WebTheme } from './src/constants/Theme';
//@ts-ignore
import Base64 from 'base-64';
global.atob = Base64.encode;


export default function App() {
  return (
    <ThemeProvider theme={WebTheme}>
    <RecoilRoot>
      <Navigation />
    </RecoilRoot>
    </ThemeProvider>
  );
}
