import React from 'react';
import { Platform } from 'react-native';
import * as Location from 'expo-location';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { currentUser, myLocation } from '../store/atoms';
import { Firestore } from '../constants/Firebase';
import { ClimberProfile } from '../../types';
const {useEffect,useState}=React;
 
interface myLocationProp {
    "coords": {
      "accuracy": number,
      "altitude": number,
      "altitudeAccuracy": number,
      "heading": number,
      "latitude": number,
      "longitude": number,
      "speed": number,
    },
    "timestamp": number,
  }
const MyLocation:myLocationProp = {
    "coords": {
      "accuracy": 0,
      "altitude": 0,
      "altitudeAccuracy": 0,
      "heading": 0,
      "latitude": 0,
      "longitude": 0,
      "speed": 0,
    },
    "timestamp": 0,
  }
const InitialState = {loading:false,location:MyLocation,error:false,errorMsg:''};
export const useLocation = ()=>{
    const [state, setState] = useState(InitialState);
    const setLocation = useSetRecoilState(myLocation);
    const [user, setUser] = useRecoilState<ClimberProfile>(currentUser);

    const updateUserLocation = (user:ClimberProfile) => {
      Firestore.collection('Users').doc(user.uid).update(user)
      .catch((err)=>{
        console.log('updateLocation',err.message);
      })
    }
 
    useEffect(() => {
      if( Platform.OS == 'web' ) return;
        setState({...InitialState,loading:true});
        Location.requestPermissionsAsync()
        .then(({status})=>{
            if (status !== 'granted') {
                throw new Error('Permission to access location was denied.');
            }
            return true;
        })
        .then((access)=>{
            if(access){
                return Location.getCurrentPositionAsync({});
            }
            return null;
        })
        .then((location:any)=>{
            if(!location){
                setState({...InitialState});
                return;
            }
            //console.log('location: ', location);
            setLocation(location);
            if(user.location){
              user.location = {address:'',lat:location.coords.latitude,lon:location.coords.longitude};
              setUser(user);
              updateUserLocation(user);
            }
            
            setState({...InitialState,location});
        })
        .catch((error)=>{
            setState({...InitialState,error:true,errorMsg:error.message});
        });
      }, []);
    return {...state};
}