import { DefaultTheme } from 'react-native-paper';
import { createMuiTheme } from '@material-ui/core/styles';

export const DarkTheme: ReactNativePaper.Theme = {
    ...DefaultTheme,
    dark: true,
    roundness: 4,
    colors: {
      ...DefaultTheme.colors,
      primary: '#4869CD',
      accent: '#03dac4',
      background: 'black',
      surface: 'black',
      error: '#B00020',
      text: 'white',
      onSurface: '#000000',
      disabled: '#7A7A7A',
      placeholder: '#7A7A7A',
      //backdrop: color('black').alpha(0.5).rgb().string(),
      //notification: 'pinkA400',
    },
};

export const LightTheme: ReactNativePaper.Theme = {
    ...DefaultTheme,
    dark: false,
    roundness: 4,
    colors: {
      ...DefaultTheme.colors,
      primary: '#4869CD',
      accent: '#03dac4',
      background: 'white',
      surface: 'white',
      error: '#B00020',
      text: '#1D1D1D',
      onSurface: '#000000',
      disabled: '#7A7A7A',
      placeholder: '#7A7A7A',
      //backdrop: color('black').alpha(0.5).rgb().string(),
      //notification: 'pinkA400',
    },
};

export const WebTheme: any = createMuiTheme({
  palette: {
    type:"light",
    background: {
      paper: '#fff',
      default: '#fff'
    },
    primary: {
      main: "#4869CD",
    },
    secondary: {
      main: '#03dac4',
    },
    error: {
      main: '#f15656',
    },
    text: {
      primary: '#1D1D1D',
      secondary: '#4869CD',
      disabled: '#7A7A7A',
      hint: '#50D8D7',
      //divider: '#69626D'
    }
  },
  typography: {
    "fontFamily": `"Arial"`,
    "fontSize": 12,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }
});

export const currentTheme = ( mode: ('light' | 'dark' | undefined) )=>{
  switch(mode){
    case 'light':
      return LightTheme;
    case 'dark':
      return DarkTheme;
    default:
      return LightTheme    
  }
}

export default {
  currentTheme,
  DarkTheme,
  LightTheme,
  WebTheme,
}  