import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Page from '../components/Page';
import { useGetAllUsers } from '../hooks/useAllUsers';
import Strings from '../constants/Strings';
import { ClimberProfile } from '../../types';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Firestore } from '../constants/Firebase';
import Paper from '@material-ui/core/Paper';
import { addEquipmentSchema } from '../constants/Schemas';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import RNPickerSelect from 'react-native-picker-select';
import { Switch } from 'react-native-paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    borderRadius: 15,
  },
  paper: {
    //marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  iconBtn: {
    borderRadius: 10,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
    width: 200,
    height: 200,
    objectFit: 'contain',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function EqipmentPage() {
  const classes = useStyles();
  const ref = Firestore.collection('Equipment');
  const [loading, setLoading] = useState(false);
  const [equipment, setEquipment] = useState(useState<string[]>([]));
  const [personalEquipment, setPersonalEquipment] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState<string>('');
  const [switchVal, setSwitchVal] = useState<boolean[]>([]);
  const [personalSwitch, setPersonalSwitch] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let ClimbTypeNames:{label:string,value:string}[] = [];
  types.map((type: string) => {
    ClimbTypeNames.push({ label: type, value: type });
  });
  ClimbTypeNames.push({ label: 'Personal', value: 'personal' });
  const formValues = {
    type: '',
    climb: ClimbTypeNames[0].label,
    anchorEl:null,
    open:false
  };
  const addPersonalEquipment = async (equip: string) => {
    let newEquip = [...personalEquipment];
    if( newEquip.includes(equip) ) {
      return;
    }
    newEquip.push(equip);
    newEquip.sort();
    await ref.doc('Personal').update({ equipmentv: newEquip.sort() });
    setPersonalEquipment(newEquip);
  };
  const removePersonalEquipment = async (equip: string) => {
    let newEquip = [...personalEquipment];
    newEquip = newEquip.filter((item) => item != equip);
    await ref.doc('Personal').update({ equipmentv: newEquip });
    newEquip.sort();
    setPersonalEquipment(newEquip);
  };

  const removeEquipment = async (equip: string, index: number, type: string) => {
    let newEquip:any = [...equipment];
    // console.log('index',index,newEquip[index].value)
    // console.log('type',type)
    // console.log('equip',equip)
    // //console.log('equip',newEquip[index].equipment);
    // return;
    newEquip[index].equipment = newEquip[index].equipment.filter((item:string) => {
      //console.log(item);
      return item != equip;
    });
    setEquipment(newEquip);
    await ref.doc(type).update({ equipment: newEquip[index].equipment });
  };

  const addEquipment = async (equip: string, index: number, type: string) => {
    let newEquip:any = [...equipment];
    if( newEquip[index].equipment.includes(equip) ) {
      return;
    }
    newEquip[index].equipment.push(equip);
    await ref.doc(type).update({ equipment: newEquip[index].equipment });
    setEquipment(newEquip);
    // setPersonalEquipment(newEquip);
  };

  const addHelper = (equip: string, type: string, closeModal: ()=>void) => {
    type = selectedType;
    if (type == 'personal') {
      addPersonalEquipment(equip);
    } else {
      equipment.map((item:any, index: number) => {
        if (item.value && item.value == type) {
          addEquipment(equip, index, type);
          addPersonalEquipment(equip);
        }
      });
    }
    closeModal();
  };

  const getAllEquipment = async () => {
    await ref.get().then((querySnapshot) => {
      const documents: any = querySnapshot.docs.map((doc) => doc.data());
      setEquipment(documents);
      documents.map((equipType: any) => {
        if (equipType.value == 'personal') {
          setPersonalEquipment(equipType.equipmentv);
          //console.log(equipType.equipmentv);
        }
      });
    });
  };

  const RenderAllEquip = ({selected}:{selected:string}) => {
    return (
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell align='center' colSpan={3}>
              <CircularProgress color='primary' />
            </TableCell>
          </TableRow>
        ) : (
          equipment.map((equip: any, index: number) => {
            //console.log('equip: ', equip);
            if (
              equip.value &&
              selected != 'personal' &&
              equip.value == selected
            )
              return (
                <React.Fragment key={equip.label}>
                  {/* <TableRow>
                    <TableCell align='center' variant='head'>
                      {equip.label}
                    </TableCell>
                  </TableRow> */}
                  {equip.equipment.map((item: string,index:number) => {
                    return (
                      <React.Fragment key={item+index}>
                        <TableRow>
                          <TableCell align='center'>{item}</TableCell>
                          <TableCell align='center'>
                            <Button
                              variant='contained'
                              color='primary'
                              size={'small'}
                              onClick={() => {
                                removeEquipment(item, index, equip.label);
                              }}
                            >
                              remove
                            </Button>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
          })
        )}
      </TableBody>
    );
  };

  const getClimbTypes = async () => {
    setLoading(true);
    Firestore.collection('ClimbTypes')
      .doc('ClimbTypes')
      .get()
      .then((types: any) => {
        //console.log(types.data().ClimbTypes);
        setTypes(types.data().ClimbTypes.sort());
        setLoading(false);
        console.log(types.data().ClimbTypes.sort());
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllEquipment();
    getClimbTypes();
  }, []);
  
  const RenderPersonalEquip = () => {
    return (
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell align='center' colSpan={3}>
              <CircularProgress color='primary' />
            </TableCell>
          </TableRow>
        ) : (
          personalEquipment.map((equip: string, index: number) => {
            return (
              <TableRow key={index}>
                <TableCell align='center'>{equip}</TableCell>
                <TableCell align='center'>
                  <Button
                    variant='contained'
                    color='primary'
                    size={'small'}
                    onClick={() => {
                      removePersonalEquipment(equip);
                    }}
                  >
                    remove
                  </Button>
                </TableCell>
              </TableRow>
            );
          })
        )}
      </TableBody>
    );
  };

  return (
    <Page title='Dashboard' layout='dashboard'>
      <Formik
        initialValues={formValues}
        validationSchema={addEquipmentSchema}
        onSubmit={(values, actions) => {
          addHelper(values.type, values.climb, ()=>actions.setFieldValue('open',false) );
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setSubmitting,
          setFieldError,
          setFieldValue,
          isSubmitting,
          values,
          errors,
        }) => (
          <>
          <Card className={classes.card}>
            <CardHeader
              className={classes.cardHeader}
              title={
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.iconBtn}
                  size={'large'}
                  onClick={(event)=>{
                    setFieldValue('anchorEl',event?.target);
                  }}
                >
                  {selectedType || values.climb}
                </Button>
              }
              titleTypographyProps={{variant:'body1'}}
              action={
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.iconBtn}
                  size={'small'}
                  onClick={()=>setFieldValue('open',true)}
                >
                  <AddIcon style={{color:'white'}} />
                </Button>
              }
            />
            <CardContent className={classes.paper}>
              <Menu
                id="lock-menu"
                anchorEl={values.anchorEl}
                keepMounted
                open={Boolean(values.anchorEl)}
                onClose={()=>{setFieldValue('anchorEl',null);}}
              >
                {ClimbTypeNames.map((option, index) => (
                  <MenuItem
                    key={index}
                    //disabled={index === 0}
                    //selected={index === selectedIndex}
                    onClick={(event) => {
                      //if (!index) return;
                      setFieldValue('climb', ClimbTypeNames[index].label);
                      setFieldValue('anchorEl',null);
                      setSelectedType(ClimbTypeNames[index].label);
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>Name</TableCell>
                    <TableCell align='center'>Action</TableCell>
                  </TableRow>
                </TableHead>
                {
                  (selectedType == 'personal' || selectedType == 'Personal' ) ? 
                    <>
                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell align='center' colSpan={3}>
                              <CircularProgress color='primary' />
                            </TableCell>
                          </TableRow>
                        ) : (
                          personalEquipment.map((equip: string, index: number) => {
                            return (
                              <TableRow key={index}>
                                <TableCell align='center'>{equip}</TableCell>
                                <TableCell align='center'>
                                  <Button
                                    variant='contained'
                                    color='primary'
                                    size={'small'}
                                    onClick={() => {
                                      removePersonalEquipment(equip);
                                    }}
                                  >
                                    remove
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </>
                  : 
                    <>
                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell align='center' colSpan={3}>
                              <CircularProgress color='primary' />
                            </TableCell>
                          </TableRow>
                        ) : (
                          equipment.map((equip: any, index: number) => {
                            //console.log('equip: ', equip);
                            if (
                              equip.value &&
                              selectedType != 'personal' &&
                              equip.value == (selectedType? selectedType : values.climb)
                            )
                              return (
                                <React.Fragment key={equip.label}>
                                  {/* <TableRow>
                                    <TableCell align='center' variant='head'>
                                      {equip.label}
                                    </TableCell>
                                  </TableRow> */}
                                  {equip.equipment.map((item: string,key:number) => {
                                    return (
                                      <React.Fragment key={item+key}>
                                        <TableRow>
                                          <TableCell align='center'>{item}</TableCell>
                                          <TableCell align='center'>
                                            <Button
                                              variant='contained'
                                              color='primary'
                                              size={'small'}
                                              onClick={() => {
                                                removeEquipment(item, index, equip.label);
                                              }}
                                            >
                                              remove
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    );
                                  })}
                                </React.Fragment>
                              );
                          })
                        )}
                      </TableBody>
                    </>  
                }
              </Table>
            </CardContent>
          </Card>
          <Dialog open={values.open} onClose={()=>{setFieldValue('open',false)}} aria-labelledby="form-dialog-title">
            <DialogTitle disableTypography={true} id="form-dialog-title">{values.climb}</DialogTitle>
            <DialogContent className={classes.form}>
              <TextField
                margin='dense'
                required
                fullWidth
                id='type'
                label='Add Equipment'
                name='type'
                autoComplete='type'
                //autoFocus
                value={values.type}
                onChange={handleChange('type')}
                error={errors.type ? true : false}
              />
              <Typography variant={'subtitle2'} color={'error'}>
                {errors.type}
              </Typography>
            </DialogContent> 
            <DialogActions>
              <Button onClick={()=>setFieldValue('open',false)} color="primary">Cancel</Button>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.submit}
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                ADD
              </Button>
            </DialogActions> 
          </Dialog>
          </>
        )}
      </Formik>
    </Page>
  );
}
