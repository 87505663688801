import React from 'react';
//@ts-ignore
import { Helmet } from 'react-helmet';

//layouts
import MainLayout from '../layouts/MainLayout';
import DashBoardLayout from '../layouts/DashBoardLayout';
  
interface PageProps {
    children:JSX.Element | JSX.Element[],
    className?: any,
    layout?: 'main' | 'dashboard' | 'none',
    title:string
}
const Page = ({children,layout='main',title = '',...rest}:PageProps) => {
  const Body = ()=> (
    <div
        {...rest}
      >
        <Helmet>
          <title>{'climbUnity | '+title}</title>
        </Helmet>
        {children}
      </div>
  );

  switch(layout) {
    case 'main':
      return(
        <MainLayout>
          <Body/>
        </MainLayout>
      )
    case 'dashboard':
      return(
        <DashBoardLayout>
          <Body/>
        </DashBoardLayout>
      )
    case 'none': 
    return(
      <Body/>
    )   
  }
    return (
      <div
        {...rest}
      >
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </div>
    );
}
  
  export default Page;
  