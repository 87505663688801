import React, { ReactChildren } from 'react';
//@ts-ignore
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import BuildIcon from '@material-ui/icons/Build';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useRecoilState } from 'recoil';

import { logout } from '../constants/Functions';
import { currentUser } from '../store/atoms';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://material-ui.com/'>
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;
const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const Links = [
  {key:0,icon:<DashboardIcon style={{color:'white'}} />,title:'Dashboard',to:'/admin'},
  {key:1,icon:<BarChartIcon style={{color:'white'}} />,title:'Reports',to:'/report'},
  {key:2,icon:<FilterHdrIcon style={{color:'white'}} />,title:'Climb Types',to:'/climbtypes'},
  {key:3,icon:<BuildIcon style={{color:'white'}} />,title:'Equipment',to:'/equipment'},
  {key:4,icon:<DescriptionIcon style={{color:'white'}} />,title:'Terms & Conditions',to:'/editterms'},
  {key:5,icon:<DescriptionIcon style={{color:'white'}} />,title:'Privacy Policy',to:'/editprivacy'},
];


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  bottomBar: {
    position: 'fixed',
    width: '100%',
    height: 80,
    backgroundColor: theme.palette.primary.main,
    top: 'auto',
    bottom: 0,
    zIndex:5,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    height: '98%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    marginBottom: 40
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  nameContainer: {
    display: 'flex',
    justifyContent:'flex-start'
  },
  secondaryListItems: {
    display:'flex',
    justifyContent:'center',
    position:'absolute',
    bottom:100
  },
  logoutBtn: {
    alignItems: 'center',
    borderRadius: 5,
  },
}));

interface DashboardProps {
  children: any;
}
export default function Dashboard({ children }: DashboardProps) {
  const classes = useStyles();
  
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const matches = useMediaQuery('(min-width:930px)');

  return (
    <div className={classes.root}>
      <CssBaseline />
      {
        matches?
          <Topbar />
        :
          <Bottombar />  
      }
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='lg' className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
}

const Topbar = ()=>{
  const classes = useStyles();
  const [user, setUser] = useRecoilState(currentUser);
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  return(
    <>
    <AppBar
        position='absolute'
        className={clsx(classes.appBar, open && classes.appBarShift)}
        color={'inherit'}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component='h1'
            variant='h6'
            color='inherit'
            noWrap
            className={classes.title}
          >
            
          </Typography>
          <div className={classes.nameContainer}>
            <Avatar style={{marginRight:10}} alt={user.firstName} src={user.image} />
            <p style={{marginTop:10}}>{' '+user.firstName + ' ' + user.lastName}</p>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <List style={{display:'flex',flexDirection:'row',justifyContent:'space-between',paddingTop:20,paddingLeft:50}}>
        <ListItem>
          <Link
            style={{ display: 'flex', justifyContent: 'flex-start' }}
            component={RouterLink}
            underline='none'
            variant='body2'
            to={'/'}
          >
            <img src={require('../assets/logo.svg')} width={70} height={75} />
          </Link>
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}> <ChevronLeftIcon /> </IconButton>
          </div>
        </ListItem>  
        </List>  
        <Divider />
        <List>{mainListItems}</List>
        <Divider />
        <List className={classes.secondaryListItems} style={{}}>
          <SecondaryListItems />
        </List>
      </Drawer>
    </>
  );
}

const Bottombar = () => {
  const classes = useStyles();
  const history = useHistory();
  const [user, setUser] = useRecoilState(currentUser);
  const [value, setValue] = React.useState(-1);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.bottomBar}
    >
      {
        Links.map((item,index)=>{
          return(
            <BottomNavigationAction 
              key={index}
              onClick={()=>history.push(item.to)} 
              //label={<Typography style={{color:(value == index? 'white' : 'black')}} >{item.title}</Typography>} 
              icon={item.icon} 
            />
          );
        })
      }
      <BottomNavigationAction 
        icon={<ExitToAppIcon style={{color:'white'}}/>} 
        onClick={() => {
          logout(setUser);
        }}
      />
    </BottomNavigation>
  );

  return (
    <AppBar position="fixed" color="primary" className={classes.bottomBar}>
      <Toolbar>
      {
        Links.map((item)=>{
          return(
            <Link
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              component={RouterLink}
              underline='none'
              variant='body2'
              to={item.to}
            >
              <IconButton edge="start" style={{color:'white'}} aria-label={item.title}>
                {item.icon}
              </IconButton>
            </Link>  
          );
        })
      }
      <div style={{flexGrow: 1,}} />
      <IconButton 
        edge="end"
        color="inherit"
        onClick={() => {
          logout(setUser);
        }}
      >
        <ExitToAppIcon />
      </IconButton>
      </Toolbar>
    </AppBar>
  );
}

const mainListItems = (
  <div>
    {
      Links.map((item)=>{
        return(
          <ListItem key={item.key}>
            <Link
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              component={RouterLink}
              underline='none'
              variant='body2'
              to={item.to}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText style={{color:'white'}} primary={item.title} />
            </Link>
          </ListItem>
        );
      })
    }
  </div>
);

const SecondaryListItems = () => {
  const classes = useStyles();
  const [user, setUser] = useRecoilState(currentUser);
  return (
    <div>
      <ListItem style={{width: '160%'}}>
        <Button 
          className={classes.logoutBtn} 
          variant="contained" 
          fullWidth
          color="primary" 
          size="large"
          onClick={() => {
            logout(setUser);
          }}
        >
          LOGOUT
        </Button>
      </ListItem>
    </div>
  );
};
